import React, { useState } from 'react'
import MapboxMap, { Marker } from 'react-mapbox-wrapper'
import moment from 'moment'
import styled from 'styled-components'
import { BaseTable, camelize, percent, Rating, secPercent, cityWithoutDVF, ErrorMarker, Asterich } from './Shared'

const CitySummary = ({
  city,
  nivGeo,
  inseeData,
  mapboxToken,
  mapTypeLight,
  plot,
  report
}) => {
  moment.locale('fr')
  const [map, setMap] = useState(null)

  const toLocaleStringOrNull = (value) => {
    if (value) {
      return value.toLocaleString('fr-FR')
    }
    return '-'
  }

  const onMapLoad = (mapInstance) => {
    setMap(mapInstance)
    mapInstance.addLayer({
      id: 'communesdispo-highlight',
      source: 'composite',
      'source-layer': 'communesdispo',
      type: 'fill',
      paint: {
        'fill-color': 'hsla(236, 27%, 42%, 1)',
        'fill-opacity': [
          'case',
          ['==', ['get', 'code'], city.code],
          0,
          0.3
        ]
      }
    })
  }

  return (
    <div className="d-flex flex-column pdf-content-inside">
      <div className="page-2-col align-start">
        <div>
          <BaseTable className="col-2 mb-12">
            <thead>
              <tr><th colSpan="2">Données générales</th></tr>
            </thead>
            <tbody>
              <tr>
                <td>Nombre d&apos;habitants :</td>
                <td>{toLocaleStringOrNull(inseeData.age_distribution.ens)}</td>
              </tr>
              <tr>
                <td>Nbre total de logements :</td>
                <td>{toLocaleStringOrNull(inseeData.housing_type_distribution.ens)}</td>
              </tr>
              <tr>
                <td>Nbre de ménages :</td>
                <td>{toLocaleStringOrNull(inseeData.marital_distribution.ens)}</td>
              </tr>
              <tr className="empty" />
              <tr>
                <td>% de logements sociaux :</td>
                {percent(inseeData, 'occupation_distribution', 'hlm_tenant')}
              </tr>
            </tbody>
          </BaseTable>

          { report === 'landReport' && (
            <BaseTable className="col-2 white-background mb-12">
              <thead>
                <tr><th colSpan="2">Principaux bailleurs sociaux</th></tr>
              </thead>
              <tbody>
                {
                  city.top_5_hlm_tenants.map((owner) => (
                    <tr key={owner.id}>
                      <td>{camelize(owner.name)} :</td>
                      <td>{(Math.round((owner.housing_area / city.housing_area_hlm_tenants) * 100))}<span> % du parc</span></td>
                    </tr>
                  ))
                }
              </tbody>
            </BaseTable>
          )}

          { report === 'marketReport' && (
            <BaseTable className="col-2 white-background mb-12">
              <thead>
                <tr><th colSpan="2">Statut d&apos;occupation des résidences principales</th></tr>
              </thead>
              <tbody>
                <tr>
                  <td>Propriétaires :</td>
                  <td>
                    {toLocaleStringOrNull(inseeData.occupation_distribution.owner)} {secPercent(inseeData, 'occupation_distribution', 'owner')}
                  </td>
                </tr>
                <tr>
                  <td>Locataires :</td>
                  <td>
                    {toLocaleStringOrNull(inseeData.occupation_distribution.tenant)} {secPercent(inseeData, 'occupation_distribution', 'tenant')}
                  </td>
                </tr>

                <tr>
                  <td className="bullet">Privés :</td>
                  <td className="no-bold">
                    {toLocaleStringOrNull(inseeData.occupation_distribution.private_tenant)} {secPercent(inseeData, 'occupation_distribution', 'private_tenant')}
                  </td>
                </tr>
                <tr>
                  <td className="bullet">HLM :</td>
                  <td className="no-bold">
                    {toLocaleStringOrNull(inseeData.occupation_distribution.hlm_tenant)} {secPercent(inseeData, 'occupation_distribution', 'hlm_tenant')}
                  </td>
                </tr>
                <tr>
                  <td>Logés gratuitement :</td>
                  <td>
                    {toLocaleStringOrNull(inseeData.occupation_distribution.free_housing)} {secPercent(inseeData, 'occupation_distribution', 'free_housing')}
                  </td>
                </tr>
              </tbody>
            </BaseTable>
          )}


          { report === 'marketReport' && (
            <BaseTable className="col-2 white-background mb-12">
              <thead>
                <tr><th colSpan="2">Répartition matrimoniale des ménages</th></tr>
              </thead>
              <tbody>
                <tr>
                  <td>Célibataires :</td>
                  <td>
                    {toLocaleStringOrNull(inseeData.marital_distribution.single)} {secPercent(inseeData, 'marital_distribution', 'single')}
                  </td>
                </tr>
                <tr>
                  <td>Familles monoparentales :</td>
                  <td>
                    {toLocaleStringOrNull(inseeData.marital_distribution.single_parent)} {secPercent(inseeData, 'marital_distribution', 'single_parent')}
                  </td>
                </tr>
                <tr>
                  <td>Ménages en couple :</td>
                  <td>
                    {toLocaleStringOrNull(inseeData.marital_distribution.couple)} {secPercent(inseeData, 'marital_distribution', 'couple')}
                  </td>
                </tr>
                <tr>
                  <td className="bullet">Sans enfants :</td>
                  <td className="no-bold">
                    {toLocaleStringOrNull(inseeData.marital_distribution.without_kids)}
                  </td>
                </tr>
                <tr>
                  <td className="bullet">Avec enfants :</td>
                  <td className="no-bold">
                    {toLocaleStringOrNull(inseeData.marital_distribution.with_kids)}
                  </td>
                </tr>
                <tr>
                  <td>Ménages autres sans famille:</td>
                  <td>
                    {toLocaleStringOrNull(inseeData.marital_distribution.other)} {secPercent(inseeData, 'marital_distribution', 'other')}
                  </td>
                </tr>
              </tbody>
            </BaseTable>
          )}

          <BaseTable className="col-2 mb-12">
            <thead>
              <tr><th colSpan="2">Dynamique des permis de construire</th></tr>
            </thead>
            <tbody>
              <tr>
                <td>Nbre de PC déposés ou autorisés en 2024 :</td>
                <td>{city.building_permits_n_1}</td>
              </tr>
              <tr>
                <td>Nbre de PC déposés ou autorisés en 2023 :</td>
                <td>{city.building_permits_n_2}</td>
              </tr>
              <tr>
                <td className="comment">Permis de + de 400 m² en résidentiel.</td>
              </tr>
              <tr className="empty" />
              <tr>
                <td>Typo. moyennes <br/>sur {nivGeo} **:</td>
                <td className='d-flex gap-4 justify-content-between'>
                  {
                    city.housing_typology ?
                      Object.keys(city.housing_typology).map((typ) => (
                        <div key={typ}>
                          <div className="no-bold color-light-grey">{typ === 'T6' ? 'T6+' : typ}</div>
                          <div>{parseInt(city.housing_typology[typ] * 100)}%</div>
                        </div>
                      )) : '-'
                  }
                </td>
              </tr>
            </tbody>
          </BaseTable>
        </div>

        <div>
          <CitySummaryMap>
            <MapboxMap
              accessToken={mapboxToken}
              coordinates={city.coordinates}
              bounds={[city.bbox[2], city.bbox[3], city.bbox[0], city.bbox[1]]}
              onLoad={(e) => onMapLoad(e)}
              mapboxStyle={`mapbox://styles/${mapTypeLight}`}
              preserveDrawingBuffer
            >
              { map && (
                <Marker
                  map={map}
                  coordinates={plot.coordinates}
                >
                  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11.0007 1.83313C7.45315 1.83313 4.58398 4.7023 4.58398 8.2498C4.58398 13.0623 11.0007 20.1665 11.0007 20.1665C11.0007 20.1665 17.4173 13.0623 17.4173 8.2498C17.4173 4.7023 14.5482 1.83313 11.0007 1.83313Z" fill="#D95D57"/>
                    <circle cx="11.0007" cy="8.2498" r="2.29167" fill="white" />
                  </svg>
                </Marker>
              )}
            </MapboxMap>
          </CitySummaryMap>


          {report === 'landReport' && (
            <BaseTable className="col-2">
              <thead>
                <tr>
                  <th colSpan="2">Prix moyen disponible sur {nivGeo}</th>
                  {cityWithoutDVF(city.code) && <th colSpan="2"><div className="d-flex justify-end w-full mb-5">{ErrorMarker}</div></th>}
                </tr>
              </thead>
              {cityWithoutDVF(city.code) ? (
                <tbody>
                  <tr>
                    <td className="pt-5" colSpan="4">
                      <div className="w-full d-flex justify-content-center">
                        En l'absence des données de marché complètes sur le secteur, nous ne sommes pas en mesure de vous délivrer d'estimation de prix.
                      </div>
                    </td>
                  </tr>
                </tbody>
              ) : (
                <tbody>
                  <tr>
                    <td>Appartement neuf<br/>(T3 en TVA 20%) :</td>
                    <td>
                      {Math.round(plot.city_price_new.adjusted_price_area.test).toLocaleString('fr-FR')}<span> €/m² shab pki</span> <br/> <Rating index={plot.city_price_new.confidence_index.test} pertinence={false} />
                    </td>
                  </tr>
                  <tr>
                    <td>Maison dans l’ancien :</td>
                    <td>{Math.round(plot.price_old_house.adjusted_price_area.test).toLocaleString('fr-FR')}<span> €/m²</span></td>
                  </tr>
                  <tr className="relevance comment">
                    <td>Pertinence :</td>
                    <td><Rating index={plot.price_old_house.confidence_index.test} pertinence={false} /></td>
                  </tr>
                  <tr>
                    <td>Appartement dans l’ancien :</td>
                    <td>{Math.round(plot.price_old_apartment.adjusted_price_area.test).toLocaleString('fr-FR')}<span> €/m²</span></td>
                  </tr>
                  <tr className="relevance comment">
                    <td>Pertinence :</td>
                    <td><Rating index={plot.price_old_apartment.confidence_index.test} pertinence={false} /></td>
                  </tr>
                </tbody>
              )}
            </BaseTable>
          )}
        </div>

      </div>
      <Asterich flexGrow>
        <div>
          <span className="color-candy-primary">*</span>
          Données issues du recensement INSEE 2020
          <br />
          <span className="color-candy-primary">**</span>
          Ventilation moyenne des typologies contatées dans les permis de construire déposés sur
          <br />
          {nivGeo} sur les 5 dernières années.
        </div>
      </Asterich>
    </div>
  )
}

const CitySummaryMap = styled.div`
  width: 100%;
  height: 450px;
  margin-bottom: 12px;
`

export default CitySummary
